export class Validation {
  public static validationjson = {


    "addGlobalVariables": {
      "name": {
        "required": {
          "msg": "Please enter a name for the global variable.."
        },
        "max": {
          "msg": "Name cannot exceed more than 40 characters.",
          "count": "40"
        },
        "alphaNumericOnly": {
          "msg": "Only Alpha-numeric characters are allowed."
        }
      },
      "size": {
        "positiveIntegerOnly": {
          "msg": "Please enter a positive number."
        }
      },
      "description": {
        "required": {
          "msg": "Please enter a description for the global variable."
        }
      },
      "value": {
        "required": {
          "msg": "Please enter a value."
        }
      }
    },
    "addOfflineNumber": {
      "qty": {
        "required": {
          "msg": "Quantity is Required"
        }
      }
    },
    "addTransientVariables": {
      "name": {
        "required": {
          "msg": "Please enter a name for the transient variable."
        },
        "max": {
          "msg": "Name cannot exceed more than 40 characters.",
          "count": "40"
        },
        "alphaNumericOnly": {
          "msg": "Only Alpha-numeric characters are allowed."
        }
      },
      "size": {
        "positiveIntegerOnly": {
          "msg": "Please enter a positive number."
        }
      },
      "description": {
        "required": {
          "msg": "Please enter a description for the transient variable."
        }
      }
    },
    "addPersistentVariables": {
      "name": {
        "required": {
          "msg": "Please enter a name for the persistent variable."
        },
        "max": {
          "msg": "Name cannot exceed more than 40 characters.",
          "count": "40"
        },
        "alphaNumericOnly": {
          "msg": "Only Alpha-numeric characters are allowed."
        }
      },
      "size": {
        "positiveIntegerOnly": {
          "msg": "Please enter a positive number."
        }
      },
      "description": {
        "required": {
          "msg": "Please enter a description for the persistent variable."
        }
      }
    },

    "addDeliverGroup": {
      "deliveryGroupName": {
        "required": {
          "msg": "Please enter deliver group name."
        },
        "alphaNumericSpaceOnly": {
          "msg": "Invalid Group Name. Please use only alphanumeric characters and white spaces"
        },
        "max": {
          "msg": "Should not exceed 50 characters.",
          "count": "50"
        },
      },
      "redirectTimeout": {
        "positiveInteger": {
          "msg": "Please enter positive number."
        }
      }
    },

    "logIN": {
      "emailUserName": {
        "required": {
          "msg": "User name or Email address Required."
        }
      },
      "password": {
        "required": {
          "msg": "Password Required."
        }
      }
    },
    "verifyLogin": {
      "verificationCode": {
        "required": {
          "msg": "Please enter your verification code."
        }
      }
    },
    "addCustomer": {
      "userName": {
        "required": {
          "msg": "User name Required."
        },
        "nonEmail": {
          "msg": "User name cannot be of email format."
        },
        "alphaNumericOnly": {
          "msg": "Only Alpha-numeric characters are allowed."
        }
      },
      "email": {
        "required": {
          "msg": "Email ID Required."
        },
        "email": {
          "msg": "Invalid Email ID."
        }
      },
      "firstName": {
        "required": {
          "msg": "First Name Required."
        }
      },
      "lastName": {
        "required": {
          "msg": "Last Name Required."
        }
      },
      "orgId": {
        "required": {
          "msg": "Please select a organization."
        }
      }
    },
    "forgetPasswordScreen": {
      "username": {
        "required": {
          "msg": "You must enter your Email address or User Name"
        }
      }

    }, "passwordconfirmation": {
      "username": {
        "required": {
          "msg": "You must enter your Email address or User Name"
        }
      },
      "resetcode": {
        "required": {
          "msg": "You must enter your Reset Code"
        }
      },
      "password": {
        "required": {
          "msg": "You must enter your Password"
        },
        "min": {
          "msg": "Should be of minimum 6 character long.",
          "count": "6"
        },
        "max": {
          "msg": "Should not exceed 45 characters.",
          "count": "45"
        },
        "alphaCSNumericSymbol": {
          "msg": "At least one small one capitalize word one number and a symbol",
          "count": "45"
        }
      },
      "confirmPassword": {
        "required": {
          "msg": "You must enter your Confirm Password"
        },
        "min": {
          "msg": "Should be of minimum 6 character long.",
          "count": "6"
        },
        "max": {
          "msg": "Should not exceed 45 characters.",
          "count": "45"
        },
        "alphaCSNumericSymbol": {
          "msg": "At least one small one capitalize word one number and a symbol",
          "count": "45"
        }
      }
    },


    "addWebRTC": {
      "userName": {
        "required": {
          "msg": "User name Required."
        },
        "nonEmail": {
          "msg": "User name cannot be of email format."
        }
      },
      "firstName": {
        "required": {
          "msg": "First Name Required."
        }
      },
      "lastName": {
        "required": {
          "msg": "Last Name Required."
        }
      },
      "email": {
        "required": {
          "msg": "Email ID Required."
        },
        "email": {
          "msg": "Invalid Email ID."
        }
      },
      "BAR": {
        "required": {
          "msg": "Please select a organization."
        }
      },
      "WebRTCName": {
        "required": {
          "msg": "WebRTC Name required."
        }
      },
      "password": {
        "required": {
          "msg": "Password required"
        },
        "min": {
          "msg": "Should be of minimum 6 character long.",
          "count": "6"
        },
        "max": {
          "msg": "Should not exceed 45 characters.",
          "count": "45"
        },
        "alphaCSNumericSymbol": {
          "msg": "Password should contain at least one small one capitalize word one number and a symbol",
          "count": "45"
        }
      }
    },
    "addUser": {
      "userName": {
        "required": {
          "msg": "User name Required."
        },
        "nonEmail": {
          "msg": "User name cannot be of email format."
        }
      },
      "email": {
        "required": {
          "msg": "Email ID Required."
        },
        "email": {
          "msg": "Invalid Email ID."
        }
      },
      "firstName": {
        "required": {
          "msg": "First Name Required."
        }
      },
      "lastName": {
        "required": {
          "msg": "Last Name Required."
        }
      },
      "BAR": {
        "required": {
          "msg": "Please select a organization."
        }
      },
      "password": {
        "required": {
          "msg": "Password required"
        },
        "min": {
          "msg": "Should be of minimum 6 character long.",
          "count": "6"
        },
        "max": {
          "msg": "Should not exceed 45 characters.",
          "count": "45"
        },
        "alphaCSNumericSymbol": {
          "msg": "Password should contain at least one small one capitalize word one number and a symbol",
          "count": "45"
        }
      }
    },
    "addRole": {
      "roleName": {
        "required": {
          "msg": "Role name Required."
        }
      },
      "permission": {
        "oneKeyRequired": {
          "msg": "Select at least one permission to create this role."
        }
      }
    },
    "AssignDestinationNumber": {
      "destinationNumber": {
        "required": {
          "msg": "Please choose Destination Number"
        }
      }
    },
    "AssignCostcenter": {
      "costCenter": {
        "required": {
          "msg": "Please choose Business Group"
        }
      }
    },
    "addEditDestinationNumber": {
      "phoneNumber": {
        "required": {
          "msg": "Phone number Required."
        },
        "phoneNumberOnly": {
          "msg": "Invalid phone number."
        }
      },
      "description": {
        "required": {
          "msg": "Description Required."
        }
      }
    },
    "downLoadDestinationNumber": {
      "fileName": {
        "required": {
          "msg": "File name Required."
        }
      }
    },
    "callReport": {
      "fromDate": {
        "required": {
          "msg": "From Date Required."
        }
      },
      "toDate": {
        "required": {
          "msg": "To Date Required."
        },
        "greaterThan": {
          "msg": "To date should be greater From Date.",
          "field": "fromDate"
        }
      },
      "phoneNumbers": {
        "oneKeyRequired": {
          "msg": "Select a Phone Number."
        }
      }
    },
    "addEditAddress": {
      "friendlyName": {
        "required": {
          "msg": "Friendly Name is Required."
        }
      },
      "street": {
        "required": {
          "msg": "Address Line1 is Required."
        }
      },
      "city": {
        "required": {
          "msg": "City is Required."
        }
      },
      "region": {
        "required": {
          "msg": "Region is Required."
        }
      },
      "postalCode": {
        "required": {
          "msg": "Postal code is Required."
        }
      }
    },
    "addLegalEntities": {
      "legalEntity": {
        "required": {
          "msg": "Legal Entity name is required."
        }
      },
      "accountSID": {
        "required": {
          "msg": "AccountSID is required."
        }
      },
      "authToken": {
        "required": {
          "msg": "Auth Token is Required."
        }
      }
    },
    "addCustomerAnnouncement": {
      "message": {
        "required": {
          "msg": "Message is required."
        }
      }
    },
    "linkUsagePlan": {
      "usagePlan": {
        "required": {
          "msg": "Usage Plan is required."
        }
      }
    },
    "createCallFlow": {
      "callFlowDisplayName": {
        "required": {
          "msg": "Call flow name is required."
        }
      }
    },
    "addAudioAnnouncement": {
      "friendlyName": {
        "required": {
          "msg": "Friendly Name is Required."
        }
      },
      "description": {
        "required": {
          "msg": "Description is Required."
        }
      }
    },
    "addEditCustomerOrg": {
      "orgName": {
        "required": {
          "msg": "Organisation Name is Required."
        },
        "orgName": {
          "msg": "Only Alpha-numeric, space and ' are allowed."
        }
      },
      "externalID": {
        "required": {
          "msg": "External ID is Required."
        },
        "alphaNumericOnly": {
          "msg": "Only Alpha-numeric characters are allowed."
        }
      }
    },
    "addEditQueue": {
      "friendlyName": {
        "required": {
          "msg": "Friendly Name is Required."
        },
        "alphaNumericSpaceOnly": {
          "msg": "Only Alpha-numeric characters are allowed."
        }
      },
      "destinationNumber": {
        "required": {
          "msg": "Destination Number is Required."
        }
      },
      "targetCapacity": {
        // "required": {
        //   "msg": "Target Capacity value is Required."
        // },
        "positiveIntegerOnly": {
          "msg": "Target Capacity value should be greater than 0."
        }
      }
      // "maxQueueLimit": {
      //   "required": {
      //     "msg": "Maximum Queued Calls value is Required."
      //   }
      // }
    }
  }
}
