import {IVModules} from '../app/shared/common/ivmodules.constant';
import {AutoSaveConst} from '../app/shared/common/autosave.constant';

export const environment = {
  production: true,
  defaultNumbersCnt: 10,
  defaultCountryCode: 'GB',
  apiConfigFile: 'dev',
  cognito: {
    userPoolId: 'eu-west-1_tvTey8w93',
    clientId: 'ooqr84avjedp2kepna38pgunp',
    cognitoLoginTimeSpan: 30,
    region: 'eu-west-1',
    identityPoolId: 'eu-west-1:bb96152d-c135-4f02-9ab9-f1ef77f9d308'
  },
  contentLimitInList: 30, // character,
  defaultCurrency: `€`,
  defaultCurrencyName: `Euro`,
  settingsModuleFeatures: [IVModules.DELIVERY_GROUP, IVModules.VARIABLES, IVModules.CALLFLOW_EDITOR],
  // portal Session activity params
  refreshAccessTokenTime: 50, // in minutes default-50 -- time to get new refresh token before current accesstoken expires(60 mins)
  inactiveSessionLogoutTime: 30, // in minutes default-30 -- time to loggout user when they are not active with their portal session
  autoSaveURLs: [AutoSaveConst.CALL_FLOW_EDITOR]// Add URLs in this array where you want to enable AutoSave service in Future
};
